<template>
    <v-navigation-drawer
            id="core-navigation-drawer"
            v-model="drawer"
            :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
            :expand-on-hover="expandOnHover"
            :right="$vuetify.rtl"
            :src="barImage"
            mobile-break-point="960"
            app
            width="260"
            v-bind="$attrs"
    >
        <template v-slot:img="props">
            <v-img
                    :gradient="`to bottom, ${barColor}`"
                    v-bind="props"
            />
        </template>

        <v-divider class="mb-1"/>

        <v-list
                dense
                nav
        >
            <v-list-item>
                <v-list-item-avatar
                        class="align-self-center"
                        color="white"
                        contain
                >
                    <v-img
                            src="https://demos.creative-tim.com/vuetify-material-dashboard/favicon.ico"
                            max-height="30"
                    />
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title
                            class="display-1"
                            v-text="profile.title"
                    />
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider class="mb-2"/>

        <v-list
                expand
                nav
        >
            <!-- Style cascading bug  -->
            <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
            <div/>

            <template v-for="(item, i) in computedItems">
                <base-item-group
                        v-if="item.children"
                        :key="`group-${i}`"
                        :item="item"
                        :subGroup="item.subGroup"
                >
                    <slot>sds</slot>
                    <!--  -->
                </base-item-group>

                <base-item
                        v-else
                        :key="`item-${i}`"
                        :item="item"
                />
            </template>

            <!-- Style cascading bug  -->
            <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
            <div/>
        </v-list>

        <template v-slot:append>
            <base-item
                    :item="{
          title: $t('upgrade'),
          icon: 'mdi-package-up',
          to: '/upgrade',
        }"
            />
        </template>
    </v-navigation-drawer>
</template>

<script>
    // Utilities
    import {
        mapState,
    } from 'vuex'

    export default {
        name: 'DashboardCoreDrawer',

        props: {
            expandOnHover: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            items: [
                {
                    icon: 'mdi-view-dashboard',
                    title: 'dashboard',
                    to: '/',
                },
                {
                    icon: 'mdi-view-dashboard',
                    title: 'login',
                    to: '/login',
                },
                {
                    icon: 'mdi-account',
                    title: 'Produk',
//                    group: 'barang',
                    children: [
                        {
                            subGroup: true,
                            icon: 'mdi-account',
                            title: 'Kategori Produk',
//                            to: 'kategori',
                            href: 'produk/kategori'
                        },
                        {

                            title: 'Produk',
                            href: 'produk'
//                            to: 'masuk',
                        },
                        {

                            title: 'Promosi',
                            href: 'produk/promosi'
//                            to: 'masuk',
                        }
                    ]
                },
                {
                    icon: 'mdi-account',
                    title: 'inventory',
//                    group: 'barang',
                    children: [
                        {
                            subGroup: true,
                            title: 'kategori barang',
//                            to: 'kategori',
                            href: 'barang/kategori'
                        },
                        {

                            title: 'barang',
                            href: 'barang'
//                            to: 'masuk',
                        },
                        {

                            title: 'barang masuk',
                            href: 'barang/masuk'
//                            to: 'masuk',
                        }
                    ]
                },
                {
                    icon: 'mdi-account',
                    title: 'Laporan',
//                    group: 'barang',
                    children: [
                        {
                            subGroup: true,
                            title: 'Laporan Laba/Rugi',
//                            to: 'kategori',
                            href: 'laporan/labarugi'
                        },
                        {

                            title: 'Laporan Penjualan',
                            href: 'laporan/penjualan'
//                            to: 'masuk',
                        },
                        {

                            title: 'Laporan Pembelian',
                            href: 'laporan/pembelian'
//                            to: 'masuk',
                        }
                    ]
                },
                {
                    title: 'uom',
                    icon: 'mdi-clipboard-outline',
                    to: '/uom',
                },
                {
                    icon: 'mdi-view-dashboard',
                    title: 'banner',
                    to: '/banner',
                },
                {
                    title: 'Supplier',
                    icon: 'mdi-clipboard-outline',
                    to: '/suppliers',
                },
                {
                    title: 'Pelanggan',
                    icon: 'mdi-clipboard-outline',
                    to: '/customers',
                },
                {
                    title: 'Penjualan',
                    icon: 'mdi-clipboard-outline',
                    to: '/sales',
                },
                {
                    icon: 'mdi-cog',
                    title: 'Setting',
                    children: [
                        {
                            subGroup: true,
                            title: 'Setting User',
                            href: 'setting/user'
                        }
                    ]
                },
//                {
//                    title: 'rtables',
//                    icon: 'mdi-clipboard-outline',
//                    to: '/regular-tables',
//                },
                {
                    title: 'typography',
                    icon: 'mdi-format-font',
                    to: '/components/typography',
                },
                {
                    title: 'icons',
                    icon: 'mdi-chart-bubble',
                    to: '/components/icons',
                },
                {
                    title: 'buttons',
                    icon: 'mdi-chart-bubble',
                    to: '/components/buttons',
                },
                {
                    title: 'grid',
                    icon: 'mdi-chart-bubble',
                    to: '/components/grid',
                },
                {
                    title: 'tabs',
                    icon: 'mdi-chart-bubble',
                    to: '/components/tabs',
                },
                {
                    title: 'google',
                    icon: 'mdi-map-marker',
                    to: '/maps/google-maps',
                },
                {
                    title: 'notifications',
                    icon: 'mdi-bell',
                    to: '/components/notifications',
                },
            ],
        }),

        computed: {
            ...mapState(['barColor', 'barImage', 'currentUser']),
            drawer: {
                get() {
                    return this.$store.state.drawer
                },
                set(val) {
                    this.$store.commit('SET_DRAWER', val)
                },
            },
            computedItems() {
//                console.log('computedItems')
//                console.log(this.items.map(this.mapItem))
                return this.items.map(this.mapItem)
            },
            profile() {
                return {
                    avatar: true,
//          title: this.$t('avatar'),
                    title: this.currentUser ? this.currentUser.name : this.$t('avatar'),
                }
            },
        },

        methods: {
            mapItem(item) {
                return {
                    ...item,
                    children: item.children ? item.children.map(this.mapItem) : undefined,
                    title: this.$t(item.title),
                }
            },
        },
    }
</script>

<style lang="sass">
    @import '~vuetify/src/styles/tools/_rtl.sass'

    #core-navigation-drawer
        .v-list-group__header.v-list-item--active:before
            opacity: .24

        .v-list-item
            &__icon--text,
            &__icon:first-child
                justify-content: center
                text-align: center
                width: 20px

                +ltr()
                    margin-right: 24px
                    margin-left: 12px !important

                +rtl()
                    margin-left: 24px
                    margin-right: 12px !important

        .v-list--dense
            .v-list-item
                &__icon--text,
                &__icon:first-child
                    margin-top: 10px

        .v-list-group--sub-group
            .v-list-item
                +ltr()
                    padding-left: 8px

                +rtl()
                    padding-right: 8px

            .v-list-group__header
                +ltr()
                    padding-right: 0

                +rtl()
                    padding-right: 0

                .v-list-item__icon--text
                    margin-top: 19px
                    order: 0

                .v-list-group__header__prepend-icon
                    order: 2

                    +ltr()
                        margin-right: 8px

                    +rtl()
                        margin-left: 8px
</style>
